
import { defineComponent, watchEffect } from 'vue'

import { windowState } from '@/store'

import LayoutConstructor from '@/layouts/LayoutConstructor'

export default defineComponent({
  components: {
    LayoutConstructor,
  },
  setup() {
    watchEffect(
      () => {
        document.body.style.overflow = windowState.allowScroll ? '' : 'hidden'
      },
      {
        flush: 'post',
      }
    )
  },
})
