export * from './types'
export * from './routes'

import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  },
})

export const navigationHistory: RouteLocationNormalized[] = []

router.afterEach((route) => {
  navigationHistory.push(route)
  if (navigationHistory.length > 20) {
    navigationHistory.shift()
  }
})

export default router
