import { RouteRecordRaw } from 'vue-router'
import { routeNames } from '..'

const ConfirmNewPasswordPage = () => import('@/views/auth/ConfirmNewPassword')
const ConfirmEmailPage = () => import('@/views/auth/ConfirmEmail')

const routes: RouteRecordRaw[] = [
  {
    path: '/password/reset/confirm',
    name: routeNames.AUTH_CONFIRM_NEW_PASSWORD,
    component: ConfirmNewPasswordPage,
  },
  {
    path: '/confirm-email',
    name: routeNames.AUTH_CONFIRM_EMAIL,
    component: ConfirmEmailPage,
  },
]

routes.forEach((route) => {
  route.meta ??= {}
  route.meta.layout = 'Bare'
})

export default routes
