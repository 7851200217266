import { RouteRecordRaw } from 'vue-router'
import { routeNames } from '..'
import authRoutes from './auth'

const ErrorPage = () => import('@/views/Error')

const routes: RouteRecordRaw[] = [
  ...authRoutes,
  {
    path: '/error',
    name: routeNames.ERROR,
    component: ErrorPage,
  },
  {
    path: '/:catchAll(.*)',
    name: routeNames.NOT_FOUND,
    component: ErrorPage,
  },
]

export default routes
